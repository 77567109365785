@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap");

body {
  font-family: "Ubuntu", sans-serif;
}

.th-font {
  font-family: "Kanit", sans-serif;
}
